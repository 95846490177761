import { toast } from "react-toastify";

import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
} from "../../actions/Type";
import { LOGIN_SUCCESSFULL, LOGOUT_SUCCESSFUL ,LOGGGIN_FAILED} from "../../config/api_strings";

const initialState = {
  isLoggedIn: null,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      toast.success(LOGIN_SUCCESSFULL);
      return { ...state, isLoggedIn: true };
    case LOGIN_FAILED:
      toast.error (LOGGGIN_FAILED+ action.payload);
      return { ...state, isLoggedIn: false };
    case LOGOUT_SUCCESS:
      toast.success(LOGOUT_SUCCESSFUL);
      return { ...state, isLoggedIn: false };
    case LOGOUT_FAILED:
      toast.error(action.payload);
      return { ...state, isLoggedIn: false };
    default:
      return false;
  }
};

export default AuthReducer;
