// App
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const RESET_LOADER = "RESET_LOADER";

// Auth
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const OPERATION_SUCCESS = "OPERATION_SUCCESS";
export const OPERATION_FAILED = "OPERATION_FAILED";
