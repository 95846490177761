import React, { Component } from "react";
import Slider from "react-slick";
import ComicBooks from "../../assets/images/comic-books.jpg";

import { settings } from "../Config/Carouselconfig";
import clip from "text-clipper";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa6";

export default class RootCarousel extends Component {
  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  render() {
    // Props
    const { comics, isBusy } = this.props;
    // const comicBooks = comics.data.data;
    console.log(comics);

    // // Dummy data array
    // const dummyData = Array.from({ length: 50 }, (_, index) => ({
    //   id: index + 1,
    //   title: `Spider Man ${index + 1}`,
    //   views: 241,
    //   monthlyViews: 150,
    //   rank: index + 1,
    // }));

    const mappingComicBooks = comics.map((item) => (
      <div
        key={item.data.comicbook_id}
        className="slidable-card relative pr-3 transition duration-500 w-auto transform group hover:scale-90 md:rounded-lg"
      >
        <div
          className="relative rounded-3xl"
          style={{
            backgroundImage: `url(${
              item.links.image != null || typeof item.links.image != "undefined"
                ? item.links.image
                : ComicBooks
            })`,
            backgroundSize: "cover",
            position: "relative",
            height: "200px",
          }}
        >
          <div
            className="absolute inset-0 bg-gradient-to-r from-comicCardOverlay to-transparent rounded-3xl md:rounded-lg"
            style={{ zIndex: 1 }}
          ></div>

          <div className="relative z-10 h-full px-3 md:px-5 flex flex-col justify-end pb-3 md:pb-5">
            <div className="mt-auto">
              <h2 className="text-white font-bold text-lg md:text-3xl">
                {item.data.attributes.title}
              </h2>
              <p className="text-sm text-white">
                {clip(item.data.attributes.description, 80)}
              </p>
              <div className="flex pt-2">
                <p className="text-sm md:text-lg text-white font-normal">
                  Vues: {item.data.attributes.no_of_views}
                </p>
                <p className="pl-2 md:pl-5 text-sm md:text-lg text-white font-normal">
                  Vues sur mois: {item.data.attributes.two_month_views}
                </p>
              </div>
              <div className="flex flex-row mt-1 space-x-2 items-center">
                <FaStar className="text-yellow-300" />
                <p className="text-white font-bold">
                  {item.data.attributes.average_rating}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

    return (
      <div className="my-5 px-3 md:px-10 " id="comic-book">
        <Slider {...settings} className="general_slider pt-5">
          {mappingComicBooks}
        </Slider>
      </div>
    );
  }
}