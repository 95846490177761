import React, { Component } from "react";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

import {
  EMAIL,
  EMAIL_REQUIRED,
  EMAIL_WRONG_FORMAT,
  LOGIN,
  PASSWORD,
  PASSWORD_REQUIRED,
  PASSWORD_TOO_LONG,
  PASSWORD_TOO_SHORT,
} from "../../../config/api_strings";

import { RESET_LOADER } from "../../../actions/Type";
import { ScaleLoader } from "react-spinners";
import { login } from "../../../actions/AuthAction";
import { resetLoader } from "../../../actions/AppAction";

class LoginForm extends Component {
  // component will unmount
  componentWillUnmount() {
    // function called when the user leaves this page
    this.props.resetLoader(RESET_LOADER);
  }

  render() {
    const { isLoading, login, isLoggedIn } = this.props;

    // Yup validation schema
    const loginFormValidationSchema = Yup.object().shape({
      userUsername: Yup.string()
        .required(EMAIL_REQUIRED)
        .email(EMAIL_WRONG_FORMAT),
      userPassword: Yup.string()
        .required(PASSWORD_REQUIRED)
        .min(2, PASSWORD_TOO_SHORT)
        .max(15, PASSWORD_TOO_LONG),
    });

    // Submitting login form data
    const submittingLogin = async (values) => {
      await login(values.userUsername, values.userPassword);
    };

    if (localStorage.getItem("login_role_id") === "0") {
      return <Navigate to="/dashboard" />;
    }

    return (
      <Formik
        initialValues={{
          userUsername: "",
          userPassword: "",
        }}
        validationSchema={loginFormValidationSchema}
        onSubmit={(values) => submittingLogin(values)}
      >
        {({ errors, touched, values, resetForm }) => (
          <Form className="flex flex-col w-full px-10 pt-10 pb-5 space-y-4">
            {/* Username */}
            <div className="flex flex-col justify-start space-y-3">
              <Field
                name="userUsername"
                id="userUsername"
                type="email"
                placeholder={EMAIL}
                className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50"
              />
              {errors.userUsername && touched.userUsername ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.userUsername}
                </small>
              ) : null}
            </div>
            {/* Password */}
            <div className="flex flex-col justify-start space-y-3">
              <Field
                name="userPassword"
                id="userPassword"
                type="password"
                placeholder={PASSWORD}
                className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50"
              />
              {errors.userPassword && touched.userPassword ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.userPassword}
                </small>
              ) : null}
            </div>

            {/* Submit button */}
            <button
              type="submit"
              className="py-2 bg-white border rounded-lg text-default hover:bg-lightDefault hover:border hover:border-white hover:text-white"
            >
              {isLoading ? (
                <ScaleLoader
                  height="20px"
                  width="4px"
                  radius="2px"
                  margin="2px"
                  color="#ffffff"
                />
              ) : (
                LOGIN
              )}
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    isLoading: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    resetLoader: (type) => dispatch(resetLoader(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
