import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HiMenuAlt2 } from "react-icons/hi";

import { apiWithOutCredentials } from "../../config/api";
import { encryptAndDecryptLocalStorage } from "../../actions/AuthAction";
import { LOGOUT_SUCCESSFUL, SIGN_OUT } from "../../config/api_strings";
import Endpoints from "../../config/endpoints";
import { toast } from "react-toastify";
import { LOGOUT_FAILED } from "../../actions/Type";

const Nav = () => {
  const [loading, setLoading] = useState(false);

  let history = useNavigate();



  const onLogOutButtonClicked = async () => {
    setLoading(true);
  
    try {
      const getAccessToken = await encryptAndDecryptLocalStorage(localStorage.getItem("login_user_token"), false);
      const header = {
        headers: {
          Authorization: `Bearer ${getAccessToken}`,
        },
      };
  
      await apiWithOutCredentials.get(Endpoints.getCsrfCookie);
  
      await apiWithOutCredentials
        .post(Endpoints.logout, {}, header)
        .then(() => {
          localStorage.clear();
          toast.success(LOGOUT_SUCCESSFUL);
          history("/");
        })
        .catch((error) => {
          toast.error(LOGOUT_FAILED);
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(LOGOUT_FAILED);
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="w-screen ">
      <nav className="fixed flex flex-nowrap items-center w-full mb-5 px-6 py-4 bg-transparent justify-end z-20">
        <div onClick={onLogOutButtonClicked}>
          <div className="rounded-md px-4 py-3 text-sm text-white bg-passOverColor whitespace-nowrap  cursor-pointer">
            {SIGN_OUT}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
