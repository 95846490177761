import React from "react";
import { CONTRACTED_COMPANY, POWERED_BY } from "../../config/api_strings";

// Image import
import HeroBackground from "../../assets/images/HeroImage.jpg";
import Body from "./components/Body";

function SignIn() {
  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Body />
      <p className="absolute bottom-0 mb-10 text-bottom text-lightbg">
        {POWERED_BY}
        <span className="text-lightDefault font-bold">
          {` ${CONTRACTED_COMPANY}`}
        </span>
      </p>
    </div>
  );
}

export default SignIn;
