import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

import Endpoints from "../../config/endpoints";
import Nav from "../../components/Nav.js/Nav";
import RootCarousel from "../../components/RootCaroussel/RootCarousel";
import Hero from "./Hero";
import { instance } from "../../config/api";
import HeroBackground from "../../assets/images/HeroImage.jpg";
import { encryptAndDecryptLocalStorage } from "../../actions/AuthAction";

function Dashboard({ isLoggedIn }) {
  const [totalComicBooks, setTotalComicBooks] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  useEffect(() => {
    fetchComicBooks();
  }, []);

  async function fetchComicBooks() {
    setStateLoading(true);
    try {
      const token = await encryptAndDecryptLocalStorage(
        localStorage.getItem("login_user_token"),
        false
      );
      console.log(token);
      var comicBooks = await instance.get(Endpoints.getAuthorComicBooks, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTotalComicBooks(comicBooks.data.data);
    } catch (error) {}
    setStateLoading(false);
  }

  if (localStorage.getItem("login_role_id") !== "0") {
    return <Navigate to="/" />;
  }

  if (isLoggedIn) {
    if (localStorage.getItem("login_role_id") !== "0")
      return <Navigate to="/" />;
  }

  return (
    <>
      <div
        className="w-screen min-h-screen flex flex-col justify-between"
        style={{
          backgroundImage: `url(${HeroBackground})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Nav />
        <Hero />
        <RootCarousel comics={totalComicBooks} isBusy={stateLoading} />
      </div>
      <div></div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
